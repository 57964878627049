import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure Tailwind imports are in your main CSS file
import bg from '../src/img/crystal-clear-cubes-on-black-background-blue-highlights-3.png'; // Correct path to your background image
import logo from '../src/img/logo.png'; // Correct path to your logo
import { FaFacebookF, FaInstagram, FaWhatsapp, FaLinkedinIn, FaGoogle, FaEnvelope, FaYoutube } from 'react-icons/fa'; // Importing icons
import { IoCalendarSharp } from "react-icons/io5"; // Importing IoCalendarSharp from react-icons/io5
import { FaLocationDot } from "react-icons/fa6";

// Utility function to calculate the remaining time
const calculateTimeLeft = (endDate) => {
  const now = new Date();
  const difference = endDate - now;

  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hrs: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      mins: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      secs: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    timeLeft = {
      days: 0,
      hrs: 0,
      mins: 0,
      secs: 0,
    };
  }
  return timeLeft;
};

function App() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hrs: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const endDate = new Date('2024-10-01T09:00:00'); // Set your target date here
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className="relative h-screen w-full bg-cover bg-center md:px-10 p-2"
      style={{ backgroundImage: `url(${bg})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black backdrop-blur-sm opacity-60 z-10"></div>

      {/* Content */}
      <div className="flex flex-col h-full">
        <div className="relative z-20 flex flex-col items-center justify-center h-full w-full overflow-hidden text-center text-white">
          <img src={logo} className="h-[120px] rounded-[4px] w-auto mb-4" alt="Logo" />
          <h1 className="font-bold text-4xl mb-2">Something exciting is coming your way!</h1>
          <p className="text-xs md:text-base text-stone-400 mb-8 max-w-[800px] mx-auto leading-[1.1]">
            Our brand-new website is launching soon, bringing you a fresh and improved experience. <br />
            We can't wait to share it with you. Stay tuned for update big things are just around the corner!
          </p>

          {/* Countdown Timer */}
          <div className="text-xl md:text-4xl font-bold mb-8 flex flex-col gap-2 items-center">
            <div className="flex space-x-4 text-black">
              {['days', 'hrs', 'mins', 'secs'].map((unit, index) => (
                <div key={index} className="flex flex-col shadow-md shadow-black items-center justify-center md:w-20 w-14 h-14 md:h-20 backdrop-blur-sm rounded-full bg-white bg-opacity-80">
                  <span className="block font-bold">{String(timeLeft[unit]).padStart(2, '0')}</span>
                  <span className="text-sm font-semibold text-opacity-80 text-pink-600">
                    {unit === 'hrs' || unit === 'mins' || unit === 'secs' ? unit : 'day'}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex text-2xl justify-center items-center gap-2 font-thin">
              <IoCalendarSharp />
              <h1 className="text-xl font-bold py-1 text-white px-2 rounded-full">10/01/2024</h1>
            </div>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-4 md:text-2xl text-xs mb-8">
            <a
              href="https://www.facebook.com/p/SCF-Strategies-100063586296486/?locale=en_GB"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-stone-800 rounded-full p-2 text-stone-200 duration-500 transition-all hover:text-blue-600"
            >
              <FaFacebookF />
            </a>

            <a
              href="https://www.linkedin.com/company/scfstrategies/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-stone-800 rounded-full p-2 text-stone-200 duration-500 transition-all hover:text-blue-700"
            >
              <FaLinkedinIn />
            </a>

            <a
              href="https://www.google.com/search?q=scf+strategies&rlz=1C1ONGR_enIN1100IN1100&oq=scf+strategies"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-stone-800 rounded-full p-2 text-stone-200 duration-500 transition-all hover:text-red-600"
            >
              <FaGoogle />
            </a>

            <a
              href="mailto:Eriddle@scfstrategies.com"
              className="hover:text-gray-300 bg-stone-800 rounded-full p-2 text-stone-200 duration-500 transition-all"
            >
              <FaEnvelope />
            </a>

            <a
              href="https://goo.gl/maps/SCFStrategies"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-stone-800 rounded-full p-2 text-stone-200 duration-500 transition-all hover:text-green-500"
            >
              <FaLocationDot />
            </a>
          </div>
        </div>
        <p
          className="w-full  text-center md:text-base text-xs text-white z-40"
        >
          © COPYRIGHT 2024 -
          <a
            href="https://connect.tltechnologies.net/"
            className="hover:text-red-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            TL TECHNOLOGIES
          </a>
          ALL RIGHTS RESERVED.
        </p>
      </div>
    </div>
  );
}

export default App;
